.catalog {
  display: flex;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

 

  &__left {
    position: relative;
    width: 25%;
    padding-right: 30px;

    @media (max-width: 1023px) {
      width: 100%;
      padding-right: 0;
    }
  }

  &__right {
    width: 75%;

    @media (max-width: 1023px) {
          margin-top: 7.4rem;
      width: 100%;
    }
  }

  &__title {
    margin-top: 25px;
  }

  &__info {
    margin-top: 15px;
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
    text-transform: uppercase;
    color: $text-color;
  }

  &__filter {
    margin-top: .6rem;

    @media (max-width: 480px) {
      margin-top: 0;
    }
  }

  &__list {
    margin: 0 -1rem;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 33.333%;
    padding: 0 1rem;
    margin-bottom: 6rem;
    display: flex;
    flex-direction: column;justify-content: space-between;
    align-items: flex-start;
  }

  &__img {
    display: block;
    margin-bottom: 3.2rem;
    img {
      max-width: 100%;
      vertical-align: top;
    }
  }

  &__name {
    flex-basis: 100%;
    font-size: 2.4rem;
    font-weight: 900;
    line-height: 1.38;
    text-transform: uppercase;
    word-break: break-word;
    color: $text-color;
    a {
      color: inherit;
      &:hover {
        color: $color-active;
      }
    }
  }

  &__btn {
    margin-top: 25px;

    @media (max-width: 1440px) {
      width: 200px;
    }
  }

  .pagination {
    margin-top: 70px;
  }
}

.filter {
  position: sticky;
  top:14rem;
  &__reset {
    display: flex;
    align-items: center;
    width: max-content;
    margin-right: auto;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1;
    color: $text-color;
    text-transform: none;
    transition: color .5s ease;
    i {
      margin-right: 2.1rem
    }
    &:hover {
      color: $color-active;
    }
  }

  &__list {
margin-bottom: 4.2rem;
    padding-bottom: 1.4rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.30);
  }

  &__item {
    margin-bottom: 2.4rem;

    input[type=checkbox] {
      display: none;

      &:checked + label {
          color: color-active;
        font-weight: 700;
        &::after {
          opacity: 1;
        }
      }
    }

    input[type=checkbox] + label {
      position: relative;
      display: flex;
      font-weight: 300;
      cursor: pointer;
      color: $text-color;

      &::before {
        content: '';
        position: relative;
        display: block;
        width: 1.8rem;
        min-width: 1.8rem;
        height: 1.8rem;
        margin-right: 15px;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.30);
        background-color: transparent;
      }
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: .8rem;
        min-width: .8rem;
        height: .8rem;
        cursor: pointer;
        top: .6rem;
        left:.6rem;
        background-color: $color-active;
        opacity: 0;
        transition: opacity .5s ease
      }
    }
  }

  &__item-sublist {
    display: none;
    padding-top: 15px;
    padding-left: 33px;

    &.active {
      display: block;
    }

    li {
      margin-bottom: 10px;
    }

    input[type=radio] {
      display: none;

      & + label {
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        text-transform: uppercase;
        color: $text-color;
      }

      &:checked + label {
        color: $main-color;
      }
    }
  }
  &--secondary {
    &:before, &:after {
      background-position: 0 10px;
    }
    &:before {
      top: -8px;
    }
  }
  @media screen and (max-width: 1200px){
    &--secondary {
      .filter__item input[type=checkbox]+label,
      .filter__item-sublist input[type=radio]+label {
        font-size: 13px;
      }
    }
  }
}

    @media (max-width: 1440px) {
      .catalog__name {
        font-size: 2.2rem;
      }
    }
    @media (max-width: 1366px) {
      .catalog__name {
        font-size: 2rem;
      }
    }
    @media (max-width: 1200px) {
    .catalog__item {
      width: 50%
    }
  }
    @media (max-width: 767px) {
      .catalog__name {
        font-size: 2rem;
        line-height: 3rem
      }
      .card .card__text, .card .card__description {
    font-size: 1.7rem;
}
    .card__description {
    margin-top: 1.1rem;
    line-height: 2rem;
  max-width: 100%;
}
.card__description p {
    margin: .7rem 0 1rem;
}
.s-top-banner_catalog {
  .card {
    padding-bottom: 2.3rem
  }
}
.card__description p:before {
    top: 1rem;
    }
    .filter__item input[type=checkbox] + label {
      font-size: 1.5rem;
      line-height: 1.8rem
    }
    .filter__item input[type=checkbox] + label::before {
    content: '';
    position: relative;
    display: block;
    width: 1.6rem;
    min-width: 1.6rem;
    height: 1.6rem;
  }
  .filter__item input[type=checkbox] + label::after {
    width: 0.6rem;
    min-width: 0.6rem;
    height: 0.6rem;
    top: 0.6rem;
    left: 0.6rem;
}
.filter__list {
    margin-bottom: 2.7rem;
    padding-bottom: 4px;
}
.filter__reset {
    font-size: 1.5rem;
    }
    .catalog__img {
    margin-bottom: 2.1rem;
}
.catalog__btn {
    margin-top: 1.6rem;
}
    }
    @media (max-width: 570px) {
    .catalog__item {
      width: 100%;
          margin-bottom: 4.8rem;
    }
  }