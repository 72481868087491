.product {
  padding: 16.2rem 0 50px;

  .breadcrumbs {
    margin-bottom: 5.4rem;
  }

  &__top {
    display: flex;
    align-items: center;

    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }

  &__info {
    width: 50.5%;
    padding-left: 10%;

    @media screen and (max-width: 1200px) {
      padding-left: 6.4%;
    }

    @media screen and (max-width: 960px) {
      padding-left: 0;
      width: 100%;
    }
  }

  &__title {
    margin: 0 0 8.3rem;
    font-size: 4.8rem;
    letter-spacing: -.01em;

    @media screen and (max-width: 1200px) {
      margin: 0 0 30px;
    }
  }

  &__text {
    font-size: 3.2rem;
    line-height: 3.8rem;
    color: $text-color;
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 15px;
  }

  &__list {
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $text-color;

    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__btn {
    margin-top: 40px;
  }

  &__subtitle {
    position: relative;
    text-align: center;
    margin-bottom: 4.5rem
  }

  &__text-2 {
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  &__bottom {}

  &__descr {
    padding: 10px 0 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    @media screen and (max-width: 960px) {
      padding: 0;
    }

  }

  &__har {
    padding: 61px 8% 40px;
    background: #F1F2F4;
  }

  &__col {
    width: 40%;
    font-size: 17px;
    line-height: 23px;

    p {
      margin: 0;
    }

    @media screen and (max-width: 960px) {
      width: 46%;
    }

    @media screen and (max-width: 540px) {
      width: 100%;
      margin-bottom: 20px;
    }

    ul,
    ol {
      list-style: initial;
      padding-left: 20px;
    }

    &-2 {
      width: 50%
    }
  }

  &__col--single {
    column-count: 2;
    column-gap: 100px;
    width: 100%;

    @media screen and (max-width: 960px) {
      width: 100%;
      column-count: 1;
    }
  }

  &__brands {
    margin: 4rem -1.5rem 0;
    text-transform: uppercase;
    color: $text-color;

    ul {
      display: flex;
      flex-wrap: wrap;
      padding-left: 2.2rem
    }

    li {

      margin: 0 1.5rem 1.7rem;
      width: calc(50% - 3rem)
    }
  }
}

.product-description {

  margin-top: 13.3rem;
}

.product-table-title {
  margin-top: 7.2rem;
  text-align: center;
  padding: 1.8rem 2rem;
  font-size: 3.3rem;
  font-weight: 900;
  line-height: 3.8rem;
  text-transform: uppercase;
  color: #fff;
  border-top: none;
  background: $color-active;
}

.product-table {
  padding-bottom: 3rem;
  color: #231E1E;
  position: relative;

  table {
    width: 100%;
    border-spacing: 0;
    font-size: 1.8rem;
    line-height: 2.5rem;

    @media screen and (max-width: 1200px) {
      width: auto;
    }

    td,
    th {
      border-bottom: 1px solid $color-active;
      background: #f1f2f4;
      padding: 5px 10px;
      text-align: center;
      width: 90px;

      &:first-child {
        padding-left: 20px;
        text-align: left;
        width: 250px;
      }

      &:nth-child(odd) {
        background: #dde7f3;
      }

      @media screen and (max-width: 1200px) {
        padding: 6px 8px;
      }
    }

    tr:first-child {
      font-weight: 700;
      text-transform: uppercase;

      th {
        background: #b4d1f0
      }

      height: 60px;

      @media screen and (max-width: 769px) {
        height: auto;
      }

      th:nth-child(odd) {
        background: #a6c9f0;
      }
    }

  }

  .ps__rail-x {
    opacity: 1;
    bottom: 0;
    height: .8rem;
    background: #BDBDBD;
    border-radius: 50px;
    width: 600px;

    &:hover {
      .ps__thumb-x {
        background-color: $color-active;
      }
    }

    .ps__thumb-x {
      background-color: $color-active;
      height: 100%;
      cursor: pointer;
      vertical-align: top;
      bottom: 0;
    }
  }

  @media screen and (max-width: 769px) {
    table tbody tr:first-of-type th {
      font-size: 16px;
    }
  }
}

.product-question {
  background: #F1F2F4;
  margin-top: 5rem;
  padding: 6.5rem 0 4.7rem;

  &__left {
    display: flex;
    justify-content: center;
  }


  &__title {
    color: $text-color;
    text-align: center;
    color: #000;
    font-family: $font-main;
    font-size: 3.2rem;

    @media screen and (max-width: 960px) {
      font-size: 32px;
    }
  }

  &__text {
    margin-top: 10px;
    text-align: center;

    @media screen and (max-width: 960px) {
      margin-top: 10px;
    }
  }

  &__right {
    padding: 30px 0;
  }

  &__form {
    padding: 4.2rem 0 0;
    margin: 0 auto;
    max-width: 131rem
  }

  @media screen and (max-width: 767px) {
    &__title {
      font-size: 25px;
    }

    &__form {
      padding: 15px 0;
    }
  }

  @media screen and (max-width: 540px) {
    flex-direction: column;

    &__left,
    &__right {
      width: 100%;
    }

    &__right {
      padding: 0;
    }
  }
}

.product-question+.seo-block {
  margin-top: 0
}

.models-slider {
  margin: 0 -1.4rem;
  display: flex;
  flex-wrap: wrap;
  background: #FFF;

  .news-item {
    margin: 1.4rem;
    padding-bottom: 15px;
    box-shadow: 0px 0px 20px 0px rgba(36, 129, 229, 0.20);
    background: #fff;
    width: calc(25% - 2.8rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }

  .news-item__title {
    margin-top: 2.5rem;
    text-transform: uppercase;
  }

  .news-item__more {
    margin-top: 6.1rem
  }

  .news-item__text {
    margin-top: 1.9rem;
  }

  .news-item__title,
  .news-item__more,
  .news-item__text {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }
}

.other-models {
  margin-top: 8.5rem;
  padding-bottom: 7rem
}

@media screen and (max-width: 1560px) {
  .product__title {
    font-size: 4.1rem
  }
}

@media screen and (max-width: 1365px) {


  .models-slider {

    .news-item {
      width: calc(33.333% - 2.8rem);
    }
  }

  .product__col {
    width: 40%;

    &+& {
      width: 60%;
      padding-right: 5%
    }
  }

  .product-table-title {
    margin-top: 5.2rem;
    padding: 1.6rem 2rem;
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 1023px) {
  .models-slider {

    .news-item {
      width: calc(50% - 2.8rem);
    }
  }

  .product__descr {
    flex-direction: column-reverse;
  }

  .product__col,
  .product__col+.product__col {
    width: 100%;
    padding-right: 0;
  }

  .product-question {
    margin-top: 0
  }

  .product__title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .product__title {
    font-size: 2rem;
  }

  .product-table-title,
  .product-table,
  .table-mob-har,
  .product__list,
  .product__col,
  .product-question__text {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  .table-mob-har {}

  .table-col {
    width: 69%;
    padding: 11px 18px 12px;
    background: #d7e8fb;

    &+& {
      width: 31%;
      background: #eaf3fd;
    }
  }

  .table-heading {
    font-weight: 700;
    text-transform: uppercase;

    .table-col {
      background: #afd2f7;
    }

    .table-col:nth-child(2) {
      background: #bedaf8
    }
  }

  .table-tr {
    border-bottom: 1px solid $color-active;
    display: flex;
  }

  .models-slider {
    margin: 0;

    .news-item {
      margin: 0 0 3.8rem;
      width: 100%;
    }
  }

  .product {
    padding: 2.6rem 0 1rem;
  }

  .product .breadcrumbs {
    margin-bottom: 2.4rem;
  }

  .product__text {
    font-size: 2.4rem;
    line-height: 3rem;
  }

  .product__info {
    margin-top: 2.8rem;
  }

  .product-description {
    margin-top: 4.3rem;
  }

  .product__subtitle {
    margin-bottom: 3rem;
  }

  .product__col-2 {
    margin-top: 3rem
  }

  .product__brands li {
    margin: 0 1rem 1.4rem;
    width: calc(50% - 2rem);
  }

  .product__brands {
    margin: 4rem -1rem 0;
  }

  .product__brands ul {
    padding-left: 1.2rem;
  }

  .product__har {
    padding: 61px 8% 25px;
  }

  .product__text-2 {
    display: block;
    text-align: center;
  }

  .product-table-title {
    margin-top: 1.2rem;
    padding: 1.4rem 1rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .other-models .news__wrap {
    padding: 0 2rem;
  }

  .models-slider {
    padding-bottom: 0
  }

  .other-models {
    margin-top: 5rem;
    padding-bottom: 1rem;
  }

  .product-question {
    margin-top: 0;
    padding: 4.5rem 0
  }

  .product-form {
    margin: 0;
  }

  .product-form__item {
    padding: 0;
    margin-bottom: .9rem;
    width: 100%
  }

  .product-form__btn {
    padding: 0;
  }

  .product-form__btn {
    margin-top: 0.9rem;
  }
}