//offers
.offers {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;

  p {
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 20px;
  }

  h3 {
    margin-top: 0;
  }

  ul {
    font-size: 1.6rem;
    line-height: 1.5;

    li {
      padding-left: 2.2rem;
      position: relative;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0.8rem;
        top: 1.2rem;
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        background-color: #000;
      }
    }
  }

  &__col {
    width: 32.3333%;
    padding: 54px 4% 34px 31px;
    background: $main-color;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 10000px;
      background: $main-color;
    }

    &>div {
      position: relative;
      z-index: 1
    }

    ul {
      li {
        margin-bottom: 15px;
      }
    }

    &-2 {
      background: #fff;
      padding-left: 3.5%;
      padding-right: 25px;

      &:before {
        display: none;
      }
    }
  }

  &__img {
    width: 35.3333%;

    img {
      vertical-align: top;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%
    }
  }


}

.criteria {
  .title-3 {
    max-width: 68rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__items-wrap {
    margin: 8.4rem -3rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 6.9rem;
    width: 33.333%;
    padding: 0 3rem;
  }

  &__item-text {
    position: relative;
    padding-left: 10.7rem;

    span {
      position: absolute;
      left: 0;
      top: -4px;
      font-family: inherit;
      font-style: normal;
      font-weight: 900;
      font-size: 4rem;
      line-height: 1;
      color: $main-color;
    }

    a {
      color: $color_black;
      display: inline-block;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 1023px) {
    &__item {
      align-items: flex-start;
      min-height: initial;
    }
  }

  @media screen and (max-width: 767px) {
    &__items-wrap {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      padding: 0 2rem;
      max-width: 500px;
      width: 100%;
    }
  }

}


.service-garants-list {
  margin: 0 -2.5rem;
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 33.333%;
    padding: 0 2.5rem;
    margin-bottom: 3rem
  }
}

.service-garants-list-no {
  margin-top: 40px;

  .title-4 {
    text-transform: uppercase;
  }
}

.act-customer {
  margin: 0 -3rem;
  display: flex;
  flex-wrap: wrap;

  &__col {
    padding: 0 3rem;
    width: 50%;
  }

  .title-4 {
    text-align: left;
    text-transform: uppercase;
    line-height: 1.2;
  }

  li {
    padding-left: 2.2rem;
    position: relative;
    margin-bottom: .6rem;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0.8rem;
      top: 1.2rem;
      width: .5rem;
      height: .5rem;
      border-radius: 50%;
      background-color: #000;
    }
  }
}

.bl-2 {
  margin-top: 3.3rem;
  font-size: 3.2rem;
  line-height: 1.3;
  font-weight: 500
}

.blocks-garanties {
  margin: 0 -1rem;
  display: flex;
  flex-wrap: wrap;
  counter-reset: item;

  li {
    margin: 1rem;
    width: calc(25% - 2rem);
    padding: 4.6rem 4.8rem 5.2rem;
    background: #FFF;
    box-shadow: 0px 0px 60px 0px rgba(36, 129, 229, 0.10);

    &:before {
      font-size: 4rem;
      line-height: 1;
      content: "0" counter(item) ".";
      counter-increment: item;
      display: block;
      color: $color-active;
      font-weight: 900;
      margin-bottom: 4.1rem
    }

    &.li-big {
      width: calc(50% - 2rem);
    }
  }
}

.bl-3 {
  margin-top: 3.2rem;
  font-size: 1.8rem;
  line-height: 1.3;
  font-weight: 500
}

@media screen and (max-width: 1365px) {
  .offers__col {
    padding: 32px 25px 22px 0;

    &-2 {
      padding-left: 25px;
      padding-right: 0
    }
  }
}

@media screen and (max-width: 1023px) {
  .offers {
    flex-wrap: wrap;
  }

  .offers__col {
    order: 1;
    width: 50%
  }

  .offers__col-2 {
    order: 2;
  }

  .offers__img {
    order: 3;
    margin: 0 -2rem;
    width: calc(100% + 4rem)
  }

  .offers__img img {
    height: 350px
  }

  .criteria__item {
    width: 50%;
  }

  .blocks-garanties li {
    margin: 1rem;
    width: calc(50% - 2rem);
  }
}

@media screen and (max-width: 767px) {
  .criteria__item {
    width: 100%;
  }

  .blocks-garanties li {
    margin: 1rem;
    width: calc(100% - 2rem);
  }

  .blocks-garanties li.li-big {
    width: calc(100% - 2rem);
  }

  .act-customer {
    display: block;
  }

  .act-customer__col {
    width: 100%;
  }

  .service-garants-list__item {
    width: 100%;
    margin-bottom: 1.4rem;
  }

  .offers {
    ul {
      font-size: 1.4rem;
      line-height: 1.4;
    }

    .title-4 {
      margin-bottom: 1.5rem;
    }
  }

  .offers__col {
    order: 1;
    width: 100%;
    padding-right: 0
  }

  .offers__img {
    order: 2;
  }

  .offers__col-2 {
    order: 3;
    padding: 3rem 0 3.1rem;
  }

  .offers__col:before {
    right: -10rem;
  }

  .s-criteria {
    padding: 4.9rem 0 4.7rem;
  }

  .criteria__item-text span {
    top: -1rem;
    font-size: 3rem;
  }

  .criteria__item-text {
    padding-left: 6.7rem;
  }

  .criteria__items-wrap {
    margin: 6.1rem -2rem 0;
  }

  .criteria__item {
    margin-bottom: 2.5rem
  }

  .service-garants-list__item {
    p {
      margin: .6rem 0
    }
  }

  .blocks-garanties li {
    padding: 3rem 2.8rem 3.2rem;
  }

  .blocks-garanties li:before {
    font-size: 3rem;
    margin-bottom: 1.4rem;
  }

  .act-customer .title-4 {
    font-size: 2.2rem;
    line-height: 3rem
  }

  .act-customer li {
    margin-bottom: 1.4rem;
  }

  .act-customer li:before {
    top: 0.8rem;
  }

  .bl-2 {
    margin-top: 4rem;
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
}