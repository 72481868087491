body {
  font-family: $font-main;
  color: $text-color;
  font-weight: 300;
  font-size: 1.8rem !important;
  line-height: 1.3333;
}
a {
  color: $color-active;
  &:hover {
    color: $main-color;
  }
}
html {
  font-size: 10px !important; 
}

@media (max-width: 1600px) {
  html {
    font-size: 9px !important; 
  }
}
@media (max-width: 1280px) {
  html {
    font-size: 8px !important; 
  }
}
@media (max-width: 767px) {
  html {
    font-size: 10px !important; 
  }
}
@media (max-width: 360px) {
  html {
    font-size: 9px !important; 
  }
}
* {
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

.wrap {
position: relative;
z-index: 1
}
b, strong {
    font-weight: 700;
}
h1,
h2 {
  margin: 0;
  font-weight: 900;
  font-size: 4rem;
  color: $color-active;
  text-transform: uppercase;
  font-family: $custom-font;

}

.seo-block {
  position: relative;
  margin-top: 2.4rem;
  padding: 14.2rem 0 15.6rem;
  text-align: center;
  &__title {
    max-width: 75rem;
    margin: 0 auto;
    font-weight: 900;
    font-size: 3.6rem;
    line-height: 1.2;
    color: $main-color;
    text-align: center;
    text-transform: uppercase;
  }
  .wrapper {
    position: relative;
    z-index: 1
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
  }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      vertical-align: top
    }
  }
  &__text {
    max-width: 75rem;
    margin: 40px auto 0;
    font-size: 2.4rem;
    line-height: 1.4;
    font-weight: 500;
    color: #fff;
  }

  &--secondary {
    max-width: 585px;
    margin: 0 auto;
    .seo-block__title {
      text-align: left;
    }
    .seo-block__text {
      margin: 20px 0;
    }
  }

}
.wrapper {
  padding: 0 4rem;
  max-width: 1600px;
  margin: 0  auto;
  width: 100%;
}
.wrapper-full {
  max-width: 100%;
}
.title-3 {
text-align: center;
      font-weight: 900;
      font-size: 4rem;
      line-height: 1.35;
      text-transform: uppercase;
      color: $color-active;
      margin: 0 0 6.5rem 0;
      font-family: $custom-font;
}
.title-4 {
      font-weight: 900;
      font-size: 3.2rem;
      line-height: 1.35;
      margin: 0 0 2.5rem 0;
}
.colored {
  color: $color-active;
}
.btn-3 {
  color: $color-active;
  border-bottom: 1px solid;
  font-size: 2.2rem;
  transition: font-weight .5s ease;
  &:hover {
    font-weight: 700
    } 
}
.wrapper-2 {
  max-width: 1862px
}
@media (max-width: 1365px) {
.seo-block__title {
  font-size: 3.2rem
}
.seo-block__text {
  font-size: 2rem
}
.wrapper {
  padding: 0 3rem;
}
.title-4 {
    font-size: 2.7rem;
    }
}
@media (max-width: 1023px) {
 .wrapper {
  padding: 0 2rem;
} 
.title-3 {
  font-size: 3.2rem;
  margin-bottom: 4.5rem;

}
}
@media (max-width: 767px) {
  body {
  font-size: 1.5rem !important;
  line-height: 1.2
}
h1, h2 {
    font-size: 1.8rem;
  }
.title-3 {
  font-size: 1.8rem;
  margin-bottom: 25px;

      hyphens: manual;
      letter-spacing: -0.01em
}
.wrap {
  padding-top: 5.6rem;
}
  .seo-block__title {
    font-size: 2.8rem;
        line-height: 1.33;
}
.seo-block {
    padding: 11.2rem 0 10.9rem;
  }
  .seo-block__text {
    margin-top: 3rem;
}
.mob-hide {
  display: none !important
}
.btn-3 {
  font-size: 2rem;
}
.title-4 {
    font-size: 2.2rem;
}
}

@media (min-width: 768px) {
.mob-show {
  display: none !important
}
}