.icon-arrow-top:before {
  content: "\e900";
}
.icon-date:before {
  content: "\e901";
}
.icon-mail:before {
  content: "\e902";
}
.icon-marker:before {
  content: "\e903";
}
.icon-reset:before {
  content: "\e904";
}
.icon-tel:before {
  content: "\e905";
}
.icon-time:before {
  content: "\e906";
}
