.news-page {
  &__list {
    margin: 0 -1.4rem;
    display: flex;
    flex-wrap: wrap;

    .news-item {
      margin: 0 1.4rem 2.8rem;
      width: calc(25% - 2.8rem);
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
    }
  }

  &__nav {
    text-align: center;
    margin-top: 4rem;

  }
}

@media (max-width: 1366px) {
  .news-page {
    &__list {
      .news-item {
        width: calc(33.333% - 2.8rem);
      }
    }
  }
}

@media (max-width: 1023px) {
  .news-page {
    &__list {
      .news-item {
        width: calc(50% - 2.8rem);
      }
    }
  }
}

@media (max-width: 767px) {
  .news-page {
    &__list {
      margin: 0;

      .news-item {
        margin: 0 0 3.8rem;
        width: 100%;
      }
    }
  }

  .news-page__nav {
    margin-top: -0.5rem;
  }
}