.s-offers {
  padding: 4.8rem 0 0;
  overflow: hidden;
}

.s-criteria {
  padding: 9.3rem 0 4.7rem;
  background-color: #000;
  color: #fff;
}

.s-service-garants {
  padding: 9rem 0;
  background: #F1F2F4;
  overflow: hidden;
}

.s-service-act-customer {
  padding: 8.5rem 0 9rem;
  overflow: hidden;
}

.s-director {
  background: #F1F2F4;
  padding: 4.4rem 0 8.6rem;

}

.s-company-properties {
  padding: 7.7rem 0 8.2rem;
  overflow: hidden;
}

.s-company-properties-2 {
  background: #141415;
  color: #fff;
  padding: 7.6rem 0 12rem;

  .properties__text {
    color: inherit;
  }
}

.s-company-properties-3 {
  background: #F1F2F4;
  padding: 7.6rem 0 7rem;
}

.s-quality-materials {
  padding: 7.3rem 0 10.3rem;

  .title-3 {
    max-width: 93rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.s-certificates {
  background: #F1F2F4;
  padding: 7.5rem 0 5.5rem;
  overflow: hidden;
}

.s-contacts {
  padding: 4.3rem 0 0;
  overflow: hidden;

  .breadcrumbs {
    margin-bottom: 3.9rem;
  }
}

.s-spare-parts-top {
  padding: 0 55px;
  max-width: 1920px;
  margin: 0 auto;

}

.s-spare-parts-table {
  padding: 2.3rem 0 50px;
}

.news-page {
  padding: 4.3rem 0 8.9rem;
}

.s-catalog {
  padding: 4.4rem 0;

  .breadcrumbs {
    margin-bottom: 5.9rem;
  }
}

.s-wrap-br {
  padding: 4.4rem 0 0;

  .breadcrumbs {
    margin-bottom: 5.9rem;
  }
}

@media screen and (max-width: 1023px) {
  .s-company-properties-2 {
    padding: 7.6rem 0 8rem;
  }
  .s-service-garants {
    padding: 7rem 0;
  }
  .s-criteria {
    padding: 8.3rem 0 2.7rem;
  }
}

@media screen and (max-width: 767px) {
  .s-director {
    padding: 1.8rem 0 3.6rem;
  }

  .s-company-properties {
    padding: 4.2rem 0 1.2rem;
  }

  .s-company-properties-2 {
    padding: 4.6rem 0 3rem;
  }

  .s-company-properties-3 {
    padding: 4rem 0 2rem;

  }

  .s-quality-materials {
    padding: 3.8rem 0 4rem;
  }

  .s-certificates {
    padding: 3.8rem 0 7rem;
  }

  .news-page {
    padding: 4.3rem 0 6.1rem;
  }

  .s-contacts {
    padding: 3.6rem 0 0;
  }

  .s-catalog {
    padding: 2.4rem 0;
  }

  .s-wrap-br {
    padding: 2.4rem 0 0;
  }

  .s-wrap-br .breadcrumbs {
    margin-bottom: 2.9rem;
  }

  .s-spare-parts-table {
    padding: 2.3rem 0 0;
  }

  .s-offers {
    padding: 2rem 0 0;

    .breadcrumbs {
      margin-bottom: 3rem;
    }
  }

  .s-service-garants {
    padding: 54px 0 60px 0;
  }

  .service-garants-list-no {
    margin-top: 19px;

    .title-4 {
      margin-bottom: 1rem
    }
  }

  .bl-3 {
    margin-top: 2.2rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  .s-service-act-customer {
    padding: 4.6rem 0 5.2rem;
  }
}