.advantages {
  padding: 9.1rem 0 11.5rem;

  &__title {
    position: relative;
    text-align: center;
  }

  &__list {
    max-width: 1530px;
    margin: 50px auto 0;
    padding: 0 15px;
  }

  &__item {
    display: flex;
    margin-bottom: 20px;
    margin-top: 12.5rem;

    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      @media (max-width: 1000px) {
        flex-direction: column;
      }
    }
  }

  &__img {
    position: relative;
    overflow: hidden;
    width: 51%;
    height: max-content;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      transition: transform 1s ease-in-out;
      transform: translateX(-100%) scaleX(.33);
      transform-origin: right center;
      background-color: $main-color;
    }

    &.is-loaded {
      &::before {
        transform: translateX(100%);
      }

      img {
        transform: translateX(0);
      }

      span {
        transform: translateX(0);
      }
    }

    img {
      display: block;
      max-width: 100%;
      transition: transform 1s ease-in-out;
      transform: translateX(-100%) scaleX(.8);
      transform-origin: right center;
    }

    span {
      position: absolute;
      left: 0;
      bottom: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
      text-align: center;
      padding: 15px 25px;
      background: rgba(255, 255, 255, .9);
      transition: transform 1s ease-in-out;
      transform: translateX(-100%) scaleX(.8);
      transform-origin: right center;

      @media (max-width: 480px) {
        padding: 10px;
      }
    }
  }

  &__info {
    width: 49%;
    padding: 0 0 0 9%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 1500px) {
      padding: 0 60px 0 40px;
    }

    @media (max-width: 1280px) {
      width: 50%;
      padding: 0 20px;
    }

    @media (max-width: 1000px) {
      width: 100%;
      padding: 2.6rem 0 0;
    }

  }

  &__name {
    font-size: 3.2rem;
    font-weight: 900;
    line-height: 34px;
    text-transform: uppercase;
  }

  &__descr {
    margin-top: 3rem;
    font-size: 1.8rem;
    line-height: 1.3;
  }

  &__link {
    margin-top: 30px;
  }
}

.machinery {
  display: flex;
  padding-top: 12rem;
  padding-bottom: 50px;

  .swiper-wrapper {
    width: 100%;
  }

  &__card {
    display: flex;
    background: #fff;
  }

  &__item {
    padding: 4.5rem 0;
    width: 100%;
  }

  @media (max-width: 1270px) {
    flex-direction: column;
    padding: 50px 0 0;
  }

  &__img {
    margin: -4.5rem 0;
    padding-left: 8.8rem;
    width: 55%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      vertical-align: top;
    }
  }

  &__info {
    position: relative;
    padding: 5rem 3rem 5rem 5.5%;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 44.6rem;

  }

  &__title {
    color: $text-color;
    font-size: 3.2rem;
    font-family: $font-main;
    font-weight: 900;
    line-height: 1.2;
  }

  &__link {
    margin-top: 4.3rem;

    @media (max-width: 480px) {
      font-size: 13px;
    }
  }
}

.partners {
  padding-top: 10.2rem;
  padding-bottom: 11.9rem;

  &__title {
    text-align: center;
  }

  &__list {
    margin-top: 4.3rem;
    display: flex;
    flex-wrap: wrap;

  }

  &__item {
    width: calc(25% - 1.4rem);
    min-height: 16.5rem;
    margin: .7rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 75%;
      display: inline-block;
      transition: all .4s linear;

      filter: grayscale(100%);
      opacity: 0.89;

      &:hover {
        opacity: 1;

        filter: none;
      }
    }
  }
}

.news {
  padding: 8rem 0 5rem;

  .swiper-slide {
    display: flex;
    flex-direction: column;
    height: auto;
    flex-shrink: 0;
  }

  .news-item {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    justify-content: space-between;
  }

  &__title {
    text-align: center;
  }

  &__wrap {
    position: relative;
    padding: 0 6rem;
  }

  &__link {
    @include link-more;
    display: block;
    width: max-content;
    margin-top: 40px;
    margin-left: auto;
  }
}

.bg-section {
  background: #F1F2F4;
}

@media (max-width: 1365px) {

  .advantages__name,
  .machinery__title {
    font-size: 2.8rem
  }

  .advantages__item {
    margin-top: 10rem
  }
}

@media (max-width: 1023px) {
  .advantages__list {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .advantages__item {
    margin-top: 2rem;
    margin-bottom: 8rem;
  }

  .advantages__img {
    width: 100%
  }

  .machinery__img {
    padding-left: 7.9rem;
    width: 50%;
  }

  .machinery__info {
    padding: 4rem 3rem 4rem 5%;
    width: 50%;
  }

  .partners__item {
    width: calc(33.333% - 1.4rem);
  }
}

@media (max-width: 767px) {

  .advantages__name,
  .machinery__title {
    font-size: 2.4rem;
  }

  .advantages__name {
    line-height: 3.1rem;
  }

  .machinery__card {
    display: block;
  }

  .machinery__img {
    margin: 0;
    padding-left: 0;
    width: 100%;

    img {
      height: 23.4rem
    }
  }

  .machinery__item {
    padding: 0;
  }

  .machinery__info {
    padding: 2.5rem 2.3rem 4rem;
    width: 100%;
    min-height: 0
  }

  .advantages__list {
    padding: 0
  }

  .advantages {
    padding: 2.7rem 0 2.5rem;
  }

  .advantages__descr {
    margin-top: 1rem;
    font-size: 1.5rem;
  }

  .advantages__item {
    margin-top: 10px;
    margin-bottom: 1.5rem;
  }

  .machinery {
    padding: 3.5rem 0 0;
  }

  .partners {
    padding-top: 4.5rem;
    padding-bottom: 1.9rem;
  }

  .partners__item {
    margin: 0.3rem;
    width: calc(50% - .6rem);
    min-height: 6.5rem;
    max-width: 39vw;

    img {
      max-height: 49px
    }
  }

  .partners__list {
    margin: 3.3rem -.3rem;
    justify-content: center;
  }

  .news {
    padding: 5.2rem 0 7rem;
  }

  .news__wrap {
    padding: 0 3rem;
  }

  .machinery__link {
    margin-top: 2.5rem;
  }
}

@media (min-width: 1001px) {
  .advantages__item:nth-child(even) {
    .advantages__img {
      width: 49%
    }

    .advantages__info {
      width: 51%;
      padding-left: 0;
      padding-right: 9%
    }
  }
}