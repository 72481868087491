.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  padding: 0 1.5rem;
  font-size: 2.2rem;
  line-height: 4.6rem;
  font-weight: 900;
  min-width: 28rem;
  transition: color .5s cubic-bezier(.2, .1, .2, 1);
  text-transform: uppercase;
  color: $main-color;
  border: 1px solid $color-active;
  outline: none;
  z-index: 1;
  color: $color-active;
  width: auto;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transition: transform .5s cubic-bezier(.2, .1, .2, 1);
      transform: scaleX(0);
      transform-origin: left center;
      background: $color-active;
    }
  &:hover {

    color: #fff;

    &::before {
      transform: none;
    }

  }

  &:hover {

    &--backToMain {
      cursor: pointer;
      margin: 0 auto;

      span:nth-child(2) {
        color: #000000;
        transition-delay: .2s;
        transition: color .3s ease-in-out;
      }

      &:hover {
        span:nth-child(2) {
          color: #fff;
        }
      }
    }
  }
}
  .btn-fill {
    color: #fff;

    &::before {
      transform: none;
      z-index: -2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transition: transform .5s cubic-bezier(.2, .1, .2, 1);
      transform: scaleX(0);
      transform-origin: left center;
      background: $text-color;
    }

    &:hover {
      border: 1px solid $text-color;

      &::after {
        transform: none;
      }

      .decor-left {
        background: $text-color;
      }
    }
  }
@media (max-width: 1365px) {
  .btn {
    font-size: 2rem;
    min-width: 24.6rem;
  }
}
@media (max-width: 767px) {
  .btn {
    height: 4.3rem;
    line-height: 4.1rem;
  }
}
