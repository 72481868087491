.s-404 {
  background: #000;
  position: relative;
  z-index: 9;
  padding: 15.6rem 0 9.6rem;
  text-align: center;
  img {
    max-width: 100%
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2
  }
  .top-banner__img {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__title {
    font-family: $font-main;
    margin-bottom: 1rem
  }
  &__subtitle {
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 3.2rem;
    color: #fff;
        margin-top: 0;
    margin-bottom: 6.3rem;
  }
  &__images {
    width: 100%;
    margin: 0 auto 7.3rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 70%
    }
  }

.btn-fill {
  color: #000;
}
}
  @media screen and (max-width: 767px){

.s-404 {
    padding: 5.6rem 0 7rem;
}
.s-404__title {
    margin-bottom: 0.8rem;
}
.s-404__subtitle {
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 9rem;
}
    .s-404__images img {
    max-width: 98%;
}
.btn--backToMain {
  padding-left: .5rem;
  padding-right: 0.5rem;
  width: 100%
}
  }
