.pagination {
    display: flex;

  &__link {
    padding: 0 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: $text-color;

    &:hover,
    &.active {
      color: $main-color;
    }
  }
}