@font-face {
  font-family: "HelveticaNeueCyr";
  font-weight: 300;
  font-display: swap;
  src: url("../../../fonts/HelveticaNeueCyrLight/HelveticaNeueCyrLight.woff") format('woff'),
  url("../../../fonts/HelveticaNeueCyrLight/HelveticaNeueCyrLight.woff2") format('woff2'),
  url("../../../fonts/HelveticaNeueCyrLight/HelveticaNeueCyrLight.ttf") format('truetype');
}
@font-face {
  font-family: "HelveticaNeueCyr";
  font-weight: 400;
  font-display: swap;
  src: url("../../../fonts/HelveticaNeueCyr/HelveticaNeueCyrRoman.woff") format('woff'),
  url("../../../fonts/HelveticaNeueCyr/HelveticaNeueCyrRoman.woff2") format('woff2'),
  url("../../../fonts/HelveticaNeueCyr/HelveticaNeueCyrRoman.ttf") format('truetype');
}

@font-face {
  font-family: "HelveticaNeueCyr";
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/HelveticaNeueCyrMedium/HelveticaNeueCyrMedium.woff") format('woff'),
  url("../../../fonts/HelveticaNeueCyrMedium/HelveticaNeueCyrMedium.woff2") format('woff2'),
  url("../../../fonts/HelveticaNeueCyrMedium/HelveticaNeueCyrMedium.ttf") format('truetype');
}

@font-face {
  font-family: "HelveticaNeueCyr";
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/HelveticaNeueCyrBold/HelveticaNeueCyrBold.woff") format('woff'),
  url("../../../fonts/HelveticaNeueCyrBold/HelveticaNeueCyrBold.woff2") format('woff2'),
  url("../../../fonts/HelveticaNeueCyrBold/HelveticaNeueCyrBold.ttf") format('truetype');
}
@font-face {
  font-family: "HelveticaNeueCyr";
  font-weight: 900;
  font-display: swap;
  src: url("../../../fonts/HelveticaNeueCyrBlack/HelveticaNeueCyrBlack.woff") format('woff'),
  url("../../../fonts/HelveticaNeueCyrBlack/HelveticaNeueCyrBlack.woff2") format('woff2'),
  url("../../../fonts/HelveticaNeueCyrBlack/HelveticaNeueCyrBlack.ttf") format('truetype');
}
 
@font-face {
  font-family: "drukWide";
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/drukWideBold/0b5ecd0afcf95185925504cdca01f5e3.woff") format('woff'),
  url("../../../fonts/drukWideBold/0b5ecd0afcf95185925504cdca01f5e3.woff2") format('woff2'),
  url("../../../fonts/drukWideBold/0b5ecd0afcf95185925504cdca01f5e3.ttf") format('truetype');
}


@font-face {
  font-family: "drukWide";
  font-weight: 900;
  font-display: swap;
  src: url("../../../fonts/drukWide/0b14a94b258b4600d48f974c50ab8e6a.woff") format('woff'),
  url("../../../fonts/drukWide/0b14a94b258b4600d48f974c50ab8e6a.woff2") format('woff2'),
  url("../../../fonts/drukWide/0b14a94b258b4600d48f974c50ab8e6a.ttf") format('truetype');
}

@font-face {
  font-family: 'icomoon';
  font-display: block;
  src:  url('../../../fonts/icomoon/fonts/icomoon.eot?ihyadm');
  src:  url('../../../fonts/icomoon/fonts/icomoon.eot?ihyadm#iefix') format('embedded-opentype'),
    url('../../../fonts/icomoon/fonts/icomoon.ttf?ihyadm') format('truetype'),
    url('../../../fonts/icomoon/fonts/icomoon.woff?ihyadm') format('woff'),
    url('../../../fonts/icomoon/fonts/icomoon.svg?ihyadm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

