.btn-form {
  background: none;
}
.footer-form,
.popup-form {
  width: 350px;
  label {
    position: relative;
    display: block;
    margin-bottom: 15px;

    span {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 17px;
      color: $main-color;
    }

    &:after {
      width: 100%;
      display: block;
      content: attr(data-error);
      position: absolute;
      bottom: -15px;
      text-align: right;
      left: 0;
      color: red;
      font-size: 12px;
    }
  }

  input {
    display: block;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    font-size: 20px;
    text-align: center;
    color: $text-color;
    border: 1px solid $main-color;
    outline: none;

    &::placeholder {
      color: rgba(168, 146, 94, 0.2);
    }
  }

  button {
    margin-top: 30px;
    cursor: pointer;
  }
}

.product-form {

    margin: 0 -3rem;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    &__item {
      width: 33.33%;
      padding: 0 3rem;
    }
    &__btn {
      margin-top: 1.9rem;
      padding: 0 3rem;
      width: 100%;
      text-align: center;
      .btn {
        width: calc(33.33% - 3rem);
      }
    }
  label {
    position: relative;
    display: block;
    margin-bottom: 15px;

    span {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 17px;
      color: $text-color;
    }

    &:after {
      width: 100%;
      display: block;
      content: attr(data-error);
      position: absolute;
      bottom: -15px;
      text-align: right;
      left: 0;
      color: red;
      font-size: 12px;
    }
  }
  input {
    display: block;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    font-size: inherit;
    text-align: left;
    color: $text-color;
    border: 1px solid $color-active;
    outline: none;
    background: transparent;

    &::placeholder {
      color: rgba(35, 30, 30, 0.4);
    }
  }
  button {
    cursor: pointer;
    @media screen and (max-width: 769px){
      max-width: 300px;
      width: 100%;
    }
  }
}

.form-thank {
  display: none;
  text-align: center;
  width: 350px;
  height: 150px;
  margin: 0 auto;

  &__title {
    font-size: 28px;
    font-weight: 700;
    color: $text-color;
  }

  &__text {
    margin-top: 15px;
    font-size: 20px;
    color: $text-color;
  }
}

  @media screen and (max-width: 1023px) {
.product-form {

    margin: 0 -1.5rem;
    &__item {
      padding: 0 1.5rem;
    }
    }
    .product-form__btn {
      padding: 0 1.5rem;}
  }


  @media screen and (max-width: 767px) {
.product-form input {
    font-size: 1.5rem;
    height: 4.2rem;
    padding: 0 17px;
    }
  }