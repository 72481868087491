.top-banner {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  background: #000;

  &__img {
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 0;

    img {
      width: 100%;
      vertical-align: top
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      left: 0;
      bottom: 0;
      top: 0;
      width: 55%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &:after {
      z-index: 1;
      width: 100%;
      background: rgba(0, 0, 0, 0.50);
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .card {
    z-index: 1
  }
}

.contacts {
  width: 100%;
  margin: 0 auto;

  &__title {
    margin-bottom: 39px;
    display: flex;
    align-items: center;
  }

  &__wrap {
    margin: 0 -3rem 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    padding: 0 3rem;
    width: 40.5%;
    margin-bottom: 1rem;

    &+& {
      width: 59.5%;
      max-width: 79rem
    }
  }

  &__subtitle {
    margin-bottom: 3rem;
    text-align: left;
    color: inherit;
    text-transform: uppercase;
  }

  &__contacts {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__phones {
    columns: 2;
    column-gap: 30px;

    .contacts__item:last-child {
      padding-bottom: 12rem
    }
  }

  &__item {
    padding-bottom: 30px;
    max-width: 37rem;
    position: relative;
    display: flex;
    align-items: flex-start;
    break-inside: avoid;

    i {
      color: $color-active;
      margin-right: 10px;
      font-size: 80%;
      width: 20px;
      flex-shrink: 0;
      display: inline-block;
      transform: translateY(.3rem);

      &.icon-marker {
        font-size: 120%
      }

      &.icon-mail {
        font-size: 70%
      }
    }

    &--time {
      border: 1px solid $main-color;
      padding: 1.3rem 4.7rem 1.3rem 3.5rem;
      display: flex;
    }
  }

  &__item-text {
    font-weight: normal;

    a {
      color: inherit;

      &:hover {
        color: $color-active;
      }
    }
  }

  &__itemName {
    font-weight: 700
  }

}

.map {
  height: 56.3rem;

  @media screen and (max-width: 767px) {
    height: 41.1rem;
  }
}

.gm-style-iw.gm-style-iw-c {
  color: $main-color  !important;
  font-weight: 700 !important;
  background: none !important;
  box-shadow: none !important;
}

.gm-style-iw-tc {
  display: none !important;
}

.gm-style-iw-d {
  overflow: visible !important;
  padding: 0 !important;
  margin-left: -8px !important;
}

@media screen and (max-width: 1366px) {
  .contacts__col {
    width: 35%;

    &+& {
      width: 65%
    }
  }
}

@media screen and (max-width: 767px) {
  .top-banner {
    .breadcrumbs {
      color: #fff;
      margin: 1.6rem 0 -2.7rem;

      li a {
        color: #fff;

        &:after {
          color: inherit;
        }

        &:hover {
          color: $main-color;
        }
      }
    }
  }

  .contacts__col {
    width: 100%;

    &+& {
      width: 100%
    }
  }

  .contacts__item {
    font-size: 1.5rem;
    line-height: 1.8rem;
    padding-bottom: 2.3rem;
    max-width: 100%
  }

  .contacts__item i {
    color: #2481E5;
    margin-right: 5px;
    transform: translateY(-0.1rem);
  }

  .contacts__item--time {
    padding: 1.3rem 3.5rem 1.3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .contacts__item-text>* {
      display: inline-block;
    }

  }

  .contacts__phones {
    columns: 1;

    .contacts__item i {
      transform: translateY(0.6rem);
    }

    .contacts__item:last-child {
      padding-bottom: 4rem;
    }
  }
}