.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  color: $text-color;
  margin-bottom: 7.9rem;
  li {
    a {
      color: $text-color;
      position: relative;
      padding-right: 15px;
      &:after {
        content: '/';
        position: absolute;
        top: 50%;
        margin-left: 5px;
        padding: 2px;
        transform: translateY(-50%);
        color: $text-color;
      }

      &:hover {
        color: $main-color;
      }
    }
  }
}
@media screen and (max-width: 1023px){
  .breadcrumbs {
      margin-bottom: 5.9rem;
  }
}
@media screen and (max-width: 767px){
  .breadcrumbs { 
      font-size: 12px;
      margin-bottom: 5rem;
  }
}