.news-item {
  color: $text-color;

  &:hover {
  color: $text-color;
    .news-item__img img {
      transform: scale(1.1);
    }
  }

  &__img {
    overflow: hidden;

    img {
      transition: all 0.4s linear;
      display: block;
      width: 100%;
    }
  }

  &__info {
    padding: 1.1rem 2.1rem 3rem 2.3rem;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
        flex-grow: 10;
        justify-content: space-between;
        background: #FFF;
box-shadow: 0px 0px 20px 0px rgba(36, 129, 229, 0.20);
  }
  &__date {
    font-size: 1.4rem;
    text-align: right;
  }

  &__title {
    margin-top: 1.5rem;
    font-weight: 900;
    font-size: 2.6rem;
    line-height: 3.6rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  white-space: normal;
  }

  &__text {
    margin-top: 15px;
    font-size: 1.8rem;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  white-space: normal;
  }

  &__more {
    @include link-more;
    margin-top: 2.1rem;
  }
}

@media (max-width: 1365px) {
.news-item__title {
.news-item__title {
    font-size: 2.25rem;
  }
}
}

@media (max-width: 767px) {
.news-item__title {
  font-size: 2rem;
}
.news-item__text {
    margin-top: 1.5rem;
    font-size: 1.5rem;
line-height: 1.2;
}
.news-item__date {
    text-align: left;
}
.news-item__more {
    font-size: 1.5rem;
    }
}