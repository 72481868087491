.spare-parts-table {
  &__wrap {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    padding-right: 3rem;
    width: 25%
  }

  &__right {
    width: 75%;
  }

  @media screen and (max-width: 1023px) {
    &__wrap {
      flex-direction: column;
    }

    .filter {
      margin-bottom: 20px;
    }

    &__left {
      max-width: 430px;
      margin: 0 auto;
      width: 100%
    }

    &__right {
      margin-top: 2.3rem;
      width: 100%;
    }
  }
}

.table-spare {
  position: relative;

  .ps__rail-x {
    top: 0;
    width: 600px;
    opacity: 1;
    border: 1px solid $color-active;
    border-radius: 50px;
  }

  .ps__rail-x .ps__thumb-x {
    height: 9px;
    cursor: pointer;
    background-color: $color-active;
  }

  table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    margin-bottom: 3.6rem;
  }

  th {
    padding: 1.5rem 2rem 1.3rem;
    font-size: 2.4rem;
    font-weight: 900;
    line-height: 144.7%;
    text-transform: uppercase;
    color: #fff;
    border-top: none;
    background: $color-active;
  }

  td {
    padding: 1.7rem .7rem 1.7rem 4.7rem;
    color: $color_black;
    border: 1px solid;
    border-color: #2481E5 transparent;
    border-bottom: none;
    background: #F1F2F4;

    &:nth-child(odd) {
      background: #dde7f3;


    }
  }

  tr.table-spare__heading {
    td {
      background: #b4d1f0;
      color: $color_black;
      font-weight: 700;
      text-transform: uppercase;

      &:nth-child(odd) {
        background-color: #a6c9f0;
      }
    }
  }
}

@media screen and (max-width: 767px) {

  .table-spare {
    width: 100%;

    table {
      display: block;
      margin-bottom: 2.1rem;
    }

    tbody,
    tr,
    td,
    th {
      display: block;
    }

    td,
    th {
      padding: .2rem 2rem;
      border-width: 0;

      &:empty {
        display: none;
      }
    }

    td {
      background: none;

      &:nth-child(odd) {

        background: none;
      }

      &:first-child {
        font-weight: 700;
      }
    }

    tr {
      background: rgba(36, 129, 229, 0.10);
      padding: .9rem 0 1rem;
      border-bottom: 1px solid $color-active;

      &:first-child {
        padding: 0;
        background: $color-active
      }

      &:last-child {
        border-bottom-width: 0
      }
    }

    th {

      padding: 1.5rem 2rem 1.3rem;
      font-size: 1.8rem;
      font-weight: 900;
      line-height: 2.2rem;
    }

    .table-spare__heading {
      display: none;
    }
  }
}