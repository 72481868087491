.news-inner {
  padding: 4.4rem 0 70px;

  font-size: 1.8rem;
  line-height: 2.5rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    font-family: $font-main;
    font-weight: 900;
    color: inherit;
    margin: 20px 0 20px;

    strong,
    b {
      font-weight: inherit;
    }
  }

  h2 {
    font-size: 3.2rem;
  }

  h3 {
    font-size: 3rem;
  }

  h4,
  h5,
  h6 {
    font-size: 2.5rem
  }

  a {
    transition: color .5s ease
  }

  picture {
    margin: 4.9rem 0;
    width: 100%;
    text-align: center;
    display: block;
  }

  .title-3 {
    margin-bottom: 4.2rem
  }

  .breadcrumbs {
    margin-bottom: 6.2rem;
  }

  &__img {
    img {
      display: block;
      max-width: 100%;
    }
  }

  &__descr {
    position: relative;
    padding: 7.3rem 8.5% 9.3rem;
    background: #FFF;
    box-shadow: 0px 0px 60px 0px rgba(36, 129, 229, 0.10);


    @media (max-width: 767px) {
      padding: 60px 1.6rem;
    }


    ul {
      padding-left: 20px;
      list-style-type: square;


      li {
        margin-bottom: 15px;
      }
    }

    img {
      max-width: 100%;
    }
  }

  &__title {
    color: $text-color;

    @media (max-width: 480px) {
      font-size: 30px;
    }

    @media (max-width: 360px) {
      font-size: 24px;
    }
  }

  &__subtitle {
    font-size: 25px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 360px) {
      font-size: 20px;
    }
  }

  &__date {
    margin-bottom: 1.4rem;
    color: #8D8D8D;
    font-size: 1.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;

    i {
      font-weight: 400;
      margin-right: 1.7rem;
    }

  }
}

.news-others {
  padding: 90px 0 90px;

  .title-3 {
    text-align: center;
    margin-bottom: 25px;
    color: #000;
  }
}
.news-inner-slider {
  margin-top: 6.5rem;
  padding: 0;
  @media (max-width: 480px) {
    margin-top: 40px;
  }
}
.news-inner-slider__pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  .certificates__btn--prev {
    order: 1;
  }

  .certificates__btn--next {
    order: 3;
  }

  .certificates__pagination {
    order: 2;
    min-width: 6.4rem
  }

}

@media screen and (max-width: 767px) {
  .news-inner {
    padding: 4.4rem 0 70px;

    font-size: 1.5rem;
    line-height: 2.8rem;

    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.8rem;
    }

    h4,
    h5,
    h6 {
      font-size: 1.7rem
    }
  }

  .news-others .title-3 {
    font-size: 2rem;
  }
}