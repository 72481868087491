.card {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 4rem;
    color: #fff;
      max-width: 50%;
  transform: translateY(-50%);

  &__title {
    position: relative;
    font-size: 6.4rem;
    font-weight: 900;
    line-height: 1.3;
    font-family:  $custom-font;
    letter-spacing: -.01rem;
    text-transform: uppercase;
    color: $main-color;
  }
  &__text {
    margin-top: 1.1rem;
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1.3;
    max-width: 65rem;
    span {
      color: $main-color;
    }
  }
  &__description {
    margin-top: 1rem;
    font-size: 2.6rem;
    font-weight: 300;
    line-height: 1.3;
    max-width: 75%;
    p {
      margin: 10px 0;
      padding-left: 60px;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 47px;
height: 2px;
position: absolute;
left: 0;
top: 1.6rem;
background: $main-color
      }
      &:first-child {
        margin-top: 0
      }
      &:last-child {
        margin-bottom: 0
      }
    }
  }
  &--secondary {
    position: static;
    transform: translateY(0);
    max-width: 420px;
    width: 100%;
    padding: 55px 0 30px;
    &:after {
      display: none;
    }
    &:before {
      top: 10px;
    }
    &:before, &:after {
      width: 100%;
      height: 25px;
      left: 0;
    }
  }

  @media screen and (max-width: 1560px){
    .card__text, .card__description {
      font-size: 2.2rem
    }
  }
  @media screen and (max-width: 767px){
    position: initial;
    max-width: initial;
    width: 100%;
    transform: translateY(0);
    padding: 3.3rem 2rem 5.8rem;
    &__title {
      font-size: 2.8rem;
    }
    &__text {
      margin: .8rem 0 0;
      font-size: 1.7rem;
    }
  }
  @media screen and (max-width: 769px){
    &--secondary {
      padding: 55px 0 0;
    }
  }
}
@media screen and (max-width: 1600px){
  .card__title {
    font-size: 5.5rem
  }
}
@media screen and (max-width: 1365px){
.card {
  max-width: 67%
}
  .card__title {
    font-size: 4.5rem
  }
}
@media screen and (max-width: 1023px){
  .card__title {
    font-size: 3.2rem
  }
}
@media screen and (max-width: 767px){
  .card__title {
    font-size: 2.8rem
  }
  .card {
    max-width: 100%;
}
}
@media screen and (min-width: 1601px){
.card {
  left: calc(50vw - 800px + 4rem);
}
}
