.footer {
  background: #141415;
  color: #fff;
  @media (max-width: 767px) {
    flex-direction: column;
  }



  &-contacts {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;


  &__item {
    margin: 1.5rem 9.8rem 1.5rem 0;
    position: relative;
    display: flex;
    font-size: 2rem;
    font-weight: 400;
    max-width: 45rem;
    color: #fff;
    transition: color 0.2s linear;
    i {
      color: $main-color;
      vertical-align: top;
      margin-right: 2.8rem;
      position: relative;
      z-index: 0;
      top:2px;
      &.icon-mail {
        font-size: 1.4rem
      }
      &.icon-marker {
        font-size: 2.5rem
      }
    }
    a{
      color: inherit;
    &:hover {
      color: $main-color;
    }
}
  }
  }
}
.footer-top {
  padding: 5.6rem 0 40px;
  display: flex;
  justify-content: space-between;
}
.footer-bottom {
  padding: 10px 0 10px
}
.developer {
  color: $main-color;
  font-size: 1.8rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  a {
    color: inherit;
    &:hover {
      color: $color-active;
    }
  }
}
.footer__aside {
  padding-top: 1.3rem
}
.btn-top {
  padding: .2rem .8rem .6rem;
  flex-shrink: 0;
  display: inline-flex;
  margin-bottom: 10%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
      background: 0 0;
    border: 1px solid $main-color;
    color: $color-active;
    i {
      margin-bottom: -2.5rem;
    font-size: 5.7rem;
    line-height: 1;
    transform: translateY(-3.7rem);
    transition: transform .5s ease;
    }
    span {
    position: relative;
    top: -4px;
    display: inline-block;
    vertical-align: top;
    line-height: 1;
    transition: all .3s linear;
    transition: transform .5s ease;
}
    &:hover {
    color: $color-active;
      i {
        transform: translateY(-4.5rem);
      }
      span {
        transform: translateY(-1.2rem);
      }
    }

}



@media (max-width: 1365px) {
  .footer-contacts__item {
    margin-right: 5.5rem;
    max-width: 42rem;
  }
}
@media (max-width: 1365px) and (min-width: 768px) {
  .footer-contacts__item {
    font-size: 1.8rem
  }
}

@media (max-width: 767px) {
  .footer-top {
    flex-direction: column;
    align-items: center;
    padding: 3.1rem 0 40px;
  }
  .footer__aside {
    padding-top: 0;
    margin-top: 40px;
  }

  .footer-contacts__item {
    line-height: 2.6rem;
    margin: 1.7rem 0 1.7rem 0;
  }
}