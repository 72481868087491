.hero-slider {
  position: relative;

  .wrapper {
    position: absolute;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  &__card {
    padding: 10.9rem 0 5.5rem;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:before {
      content: '';
      display: block;
      height: 1px;
    }
  }

  .swiper-slide {
    height: auto;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      left: 0;
      bottom: 0;
      top: 0;
      width: 55%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
    }

    &:after {
      z-index: 1;
      width: 100%;
      background: rgba(0, 0, 0, 0.50);
    }

  }

  &__title {
    position: relative;
    font-size: 6.4rem;
    font-weight: 900;
    line-height: 1.3;
    font-family: "drukWide", sans-serif;
    letter-spacing: -.2rem;
    text-transform: uppercase;
    color: $main-color;


  }

  &__text {
    margin-top: 1.1rem;
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1.3;
    color: #fff;

  }

  &__suggest {
    position: relative;
    margin-top: 30px;
    font-size: 2.2rem;
    line-height: 1.15;
    font-weight: 300;
    max-width: 351px;
    border: 1px solid $color-active;
    padding: 2.5rem 3rem;
    color: #fff;
    min-height: 16.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-title {
      font-family: $custom-font;
      color: $color-active;
      font-weight: 700;
      font-size: 2.4rem;
      text-transform: uppercase;
      margin-bottom: .9rem;

    }

  }

  .swiper-slide img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
    vertical-align: top;
  }

  .swiper-pagination-bullets {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    left: initial;
    display: flex;
    flex-direction: column;
    width: 4rem;
    font-family: $custom-font;
    font-weight: 700;
    font-size: 2rem;
  }

  .swiper-pagination-bullet {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    padding: 10px;
    transform: scale(1);
    opacity: 1;
    border-radius: 0;
    outline: transparent;
    background: transparent;
    border-bottom: 1px solid #fff;
    text-align: center;
    color: #fff;

    @media (max-width: 768px) {
      padding: 4px;
    }

  }

  .swiper-pagination-bullet-active {
    border-bottom-color: $main-color;
  }

  .swiper-pagination-bullet-active+span:before {
    width: 120px;
    transform: scaleX(1);

    @media (max-width: 768px) {
      width: 50px;
    }
  }

  .swiper-pagination-bullet:last-child:after {
    display: none;
  }

  &__nav {
    position: absolute;
    right: 0;
    bottom: 55px;
    z-index: 1;
    display: flex;

    @media (max-width: 768px) {
      right: initial;
      bottom: 30px;
      left: 0;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.8rem;
    height: 5.9rem;
    margin-right: 2px;
    cursor: pointer;
    outline: none;
    background: #000;

    &:hover {
      background: transparent;

      svg {
        path:first {
          stroke: #000;
        }

        path:last-child {
          fill: #000;
        }
      }
    }

    svg {
      height: auto;
      width: 6rem;
    }
  }
}

.machinery-slider {
  width: 100%;

  .swiper-slide {
    display: flex;
  }

  &__nav {
    position: absolute;
    bottom: 4rem;
    left: 0;
    z-index: 11;
    display: flex;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.8rem;
    height: 5.9rem;
    margin-right: 2px;
    cursor: pointer;
    background: #000;
    border: 1px solid #000;

    @media (max-width: 480px) {
      width: 40px;
      height: 40px;
    }

    svg {
      height: auto;
      width: 6rem
    }

    &:hover {
      background: #fff;

      svg {
        path:first-child {
          stroke: #000;
        }

        path:last-child {
          fill: #000;
        }
      }
    }

    &--next {
      background: #fff;
      margin-left: -1px;

      svg {
        path:first-child {
          stroke: #000;
        }

        path:last-child {
          fill: #000;
        }
      }

      &:hover {
        background: #000;

        svg {
          path:first-child {
            stroke: $main-color;
          }

          path:last-child {
            fill: $main-color;
          }
        }
      }
    }
  }
}

.news-slider,
.models-slider {
  position: relative;
  margin-top: 30px;

  .swiper-slide {
    padding: 0.5rem
  }

  &__btn {
    position: absolute;
    top: 9.8rem;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.8rem;
    height: 5.9rem;
    cursor: pointer;
    outline: none;
    background: #000;

    @media (max-width: 700px) {
      top: 100px;
    }


    svg {
      height: auto;
      width: 6rem
    }

    &--prev {
      left: 2.2rem;

    }

    &--next {
      right: 2.2rem;

      @media (max-width: 767px) {
        right: auto;
        left: 10.9rem;
      }
    }

    &:hover {
      background: #fff;

      svg {
        path:first-child {
          stroke: #000;
        }

        path:last-child {
          fill: #000;
        }
      }
    }
  }
}

.news-inner-slider {

  .swiper-slide img {
    max-width: 100%;
  }

  &__pagination {
    position: static;
    margin-top: 70px;

    @media (max-width: 480px) {
      margin-top: 40px;
    }

    .swiper-pagination-bullet {
      width: 100px;
      height: 4px;
      opacity: 1;
      border: 1px solid $main-color;
      border-radius: 0;
      background: none;

      @media (max-width: 768px) {
        width: 40px;
      }

      &-active {
        background: $main-color;
      }
    }
  }
}

//slider-Nav
.sliderNav {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transform: translateY(-50%);
  pointer-events: none;

  &--secondary {
    position: static;
    justify-content: center;
    width: auto;
    transform: translateY(0);
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.8rem;
    height: 5.9rem;
    margin-right: 2px;
    cursor: pointer;
    transition: background .3s ease-in-out;
    pointer-events: all;
    outline: none;
    background-color: #000;
    border: 1px solid #000;

    svg {
      height: auto;
      width: 6rem;
    }

    &:hover {
      background: #fff;

      svg {
        path:first-child {
          stroke: #000;
        }

        path:last-child {
          fill: #000;
        }
      }
    }

    &--prev {
      background: #fff;

      svg {
        path:first-child {
          stroke: #000;
        }

        path:last-child {
          fill: #000;
        }
      }

      &:hover {
        background: #000;

        svg {
          path:first-child {
            stroke: $main-color;
          }

          path:last-child {
            fill: $main-color;
          }
        }
      }
    }
  }
}

.product-slider {
  position: relative;
  width: 50%;
  min-width: 540px;

  @media screen and (max-width: 1200px) {
    min-width: 500px;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    min-width: initial;
  }


  .swiper-slide img {
    display: block;
    max-width: 100%;
  }


  &__nav {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 11;
    display: flex;

  }

  &__btn {
    border-width: 0;
    &:hover {
      background: #fff;

      svg {
        path:first-child {
          stroke: #000;
        }

        path:last-child {
          fill: #000;
        }
      }
    }

    &--prev {
      border-width: 0;
    }
    &--next {
      background: #fff;
      border-width: 0;
      margin-left: -2px;
      svg {
        path:first-child {
          stroke: #000;
        }

        path:last-child {
          fill: #000;
        }
      }

      &:hover {
        background: #000;

        svg {
          path:first-child {
            stroke: $main-color;
          }

          path:last-child {
            fill: $main-color;
          }
        }
      }
    }
  }
}
.certificates__btn {
    &:hover {
      background: transparent;

      svg {
        path:first-child {
          stroke: #000;
        }

        path:last-child {
          fill: #000;
        }
      }
    }

    &--prev {
      background: transparent;

      svg {
        path:first-child {
          stroke: #000;
        }

        path:last-child {
          fill: #000;
        }
      }

      &:hover {
        background: #000;

        svg {
          path:first-child {
            stroke: $main-color;
          }

          path:last-child {
            fill: $main-color;
          }
        }
      }
    }
}

////slider-pagination
.sliderPagination {
  position: static;
  display: flex;
  width: auto;
  height: 50px;
  margin-right: 30px;
  background: #fff;

  span.swiper-pagination-bullet {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    margin: 0 4px;
    padding: 10px;
    transform: scale(1);
    opacity: 1;
    color: #a8925e;
    border-radius: 0;
    outline: transparent;
    background: 0 0;

    &:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 1px;
      margin-right: 20px;
      transition: transform 1s cubic-bezier(.2, .1, .2, 1), width 1s cubic-bezier(.2, .1, .2, 1);
      transform: scaleX(0);
      transform-origin: left center;
      vertical-align: middle;
      background: #a8925e;
    }
  }

  span.swiper-pagination-bullet-active+span:before {
    width: 120px;
    transform: scaleX(1);
  }

  @media screen and (max-width: 960px) {
    span.swiper-pagination-bullet-active+span:before {
      width: 40px;
    }

    span.swiper-pagination-bullet {
      padding: 5px;
    }
  }

  @media screen and (max-width: 769px) {
    margin-right: 0;

    span.swiper-pagination-bullet-active+span:before {
      width: 20px;
    }
  }
}

@media (max-width: 1365px) {
  .hero-slider__title {
    font-size: 5.7rem
  }

  .hero-slider__text {
    font-size: 2rem;
  }
}

@media (max-width: 1023px) {
  .hero-slider__title {
    font-size: 4rem;
  }

  .hero-slider__suggest {
    font-size: 2rem;
    padding: 2rem 2.5rem;
    min-height: 13.5rem;
  }

  .sliderNav__btn,
  .machinery-slider__btn,
  .news-slider__btn,
  .models-slider__btn {
    width: 7.9rem;
    height: 5.3rem;

    svg {
      width: 5.5rem
    }
  }
}

@media (max-width: 767px) {
  .hero-slider .swiper-slide {

    display: flex;
    flex-direction: column-reverse;
  }

  .hero-slider .wrapper {
    position: relative;
    background: #000;
  }

  .hero-slider .swiper-slide img {
    height: auto
  }

  .hero-slider__card {
    width: 100%;
    padding: 3.9rem 0 8.8rem;
  }

  .hero-slider .swiper-slide:before {
    width: 100%;
    bottom: auto;
    height: 15rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
  }

  .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: .4rem .7rem;
    padding: .5rem 1rem;
  }

  .hero-slider__title {
    font-size: 2.8rem;
  }

  .hero-slider__text {
    font-size: 1.7rem;
    margin-top: 0.7rem;
  }

  .hero-slider__suggest {
    font-size: 1.5rem;
    margin-top: 3.2rem;
    padding: 2rem 2.8rem;
  }

  .hero-slider__suggest-title {
    font-size: 2rem;
  }

  .news-slider,
  .models-slider {
    padding-bottom: 7.1rem;
    margin: 0 -.5rem
  }

  .machinery .swiper-wrapper {
    padding-bottom: 0
  }

  .news-slider__btn {
    top: auto;
    bottom: 0
  }

  .hero-slider .swiper-pagination-bullets {
    transform: none;
    bottom: 25px;
    top: auto;
    left: 0;
    right: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .machinery-slider__nav {
    bottom: auto;
    top: 18.1rem;
  }
}