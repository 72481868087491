//companyDirector
.company-director {
  &__inner {
    padding: 16.3rem 8% 9.1rem;
    max-width: 100rem;
    position: relative;
    margin: 0 auto;
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: $color_black;
    background: #FFF;
    box-shadow: 0px 0px 60px 0px rgba(36, 129, 229, 0.10);

    &:before {
      content: '';
      position: absolute;
      background: url("../../img/about/quote.svg") top left no-repeat;
      background-size: contain;
      width: 4.8rem;
      height: 4.2rem;
      top: 7rem;
      left: 50%;
      transform: translateX(-50%);
    }

    p {
      &.company-director__sign {
        font-weight: 700;
        margin: 6.8rem 0 0;
      }
    }
  }

  p {
    margin: 2.2rem 0;
  }

  @media screen and (max-width: 1023px) {
    &__inner {
      padding: 14.3rem 8% 6.1rem;

      &:before {
        width: 30px;
        height: 25px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &__inner {
      &:before {
        width: 18px;
        height: 13px;
      }

      p {
        margin-bottom: 10px;
        margin-top: 0;
      }
    }
  }
}

.properties {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 90px;

    &-reverse {
      flex-direction: row-reverse;

      .properties__img {
        &:after {
          right: 15%;
          left: initial;
        }
      }

      .properties__text {
        padding-right: 0;
        padding-left: 4.2%;
      }

    }

    &:last-child {
      margin-bottom: 0
    }
  }

  &__img {
    position: relative;
    max-width: 75.5rem;
    width: 100%;


    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $color_black;
    max-width: 68rem;
    min-width: 42rem;
    width: 100%;
    padding-right: 5%;

    p {
      margin-top: 0;
      margin-bottom: 2.3rem;
      font-style: normal;
      font-weight: normal;
    }

    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: inherit;
      line-height: inherit;
      margin-top: 0;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    ul {
      margin: 3.4rem 0 0 1.7rem;

      li {
        padding-left: 6.7rem;
        position: relative;
        margin-bottom: 1.5rem;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 7px;
          width: 3.1rem;
          height: 3.1rem;
          background: url(../../img/decor-li.svg) 50% 50% no-repeat;
          background-size: contain;
        }
      }
    }
  }



  @media screen and (max-width: 1023px) {
    &__item {
      margin-bottom: 40px;

      h3 {
        font-size: 28px;
        line-height: 36px;
      }
    }


  }

  @media screen and (max-width: 767px) {
    &__item {
      margin-bottom: 40px;
      flex-direction: column-reverse;

      &-reverse {
        flex-direction: column-reverse;

        .properties__text {
          margin-left: 0;
        }

        .properties__img {
          &:after {
            right: 0;
          }
        }
      }
      h3 {
        font-size: 19px;
        line-height: 20px;
        margin-bottom: 30px;
      }
    }
    &__img {
      margin-bottom: 20px;
      max-width: initial;

      &:after {
        width: 100%;
        left: 0;
        top: -30px;
      }
    }

    &__left {
      width: 98%;
      margin: 0 auto;

      p {
        font-size: 16px;
      }
    }
    &__img {
      span {
        font-size: 8px;
        padding: 11px 12px;
      }
    }

    &__right {
      max-width: initial;
    }

    &__text {
      padding-right: 0;
      min-width: initial;
    }
  }
}

.quality-materials {
  position: relative;

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__img {
    max-width: 880px;
    width: 100%;
  }

  &__text {
    max-width: 49rem;
    margin-left: 30px;
    padding-top: 15px;
    font-size: 1.8rem;
    line-height: 2.5rem;

    p {
      margin-top: 0;
      margin-bottom: 15px;
      font-style: normal;
      font-weight: 400;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &__wrap {
      flex-direction: column-reverse;
    }

    &__text {
      max-width: initial;
      margin-left: 0;
    }

    &__img {
      max-width: initial;
    }
  }
}


.certificates {
  position: relative;

  &__slider {
    padding: 2rem 2rem 14rem;
    margin: 0 -2rem;
  }

  &__bottom-nav {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    top: auto;
  }

  &__pagination {
    margin: 0 22px;
    font-size: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    min-width: 6.4rem;
    .swiper-pagination-current {
      font-weight: 700
    }
  }

  &__item {
    position: relative;
    padding-bottom: 31.335%;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(36, 129, 229, 0.10);

    img {
      max-width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;

      vertical-align: top;
    }

    a {
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      bottom: 0;
      display: block;
      cursor: pointer;
      transition: opacity .3s ease-in-out;
      opacity: 0;
      background: rgba($color-active, .4) url("/img/about/zoom.svg") 50% 50% no-repeat;
      background-size: 11.9rem 11.7rem;
    }

    &:hover a {
      opacity: 1;
    }
  }

  @media screen and (max-width: 767px) {
    &__item {
      padding-bottom: 140%;

    }
  }

}

:target {
  display: flex;
  padding-top: 80px;
  margin-top: -80px;
}

.materials {
  margin: 0 -.8rem;
  display: flex;
  flex-wrap: wrap;

  &__item {
    background: #fff;
    margin: 0.8rem;
    width: calc(33.333% - 1.6rem);
    min-height: 12.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 0px 60px 0px rgba(36, 129, 229, 0.10);

    img {
      max-width: 85%
    }
  }
}

@media screen and (max-width: 1023px) {
  .quality-materials__text {
    padding-top: 0
  }

  .quality-materials__img {
    margin-top: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .company-director__inner {
    padding: 10.4rem 20px 6.1rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  .properties__text,
  .quality-materials__text {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  .materials {
    margin: 0 -.4rem;
  }

  .materials__item {
    margin: 0.4rem;
    width: calc(50% - .8rem);
    min-height: 7.4rem;

    img {
      max-height: 3.9rem
    }
  }


  .certificates__slider {
    padding-bottom: 12rem
  }
}