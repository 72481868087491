@mixin pattern {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25px;
  background: url('../../img/pattern.svg') repeat-x;
}

@mixin link-more {
  font-size: 1.8rem;
  transition: all .4s linear;
  text-transform: uppercase;
  color: $color-active;
  span {
    border-bottom: 1px solid transparent;
    transition: border-bottom-color .5s ease;
  }
  &::after {
    content: url('../../img/icons/link-arrow.svg');
    position: relative;
    top: -2px;
    margin-left: 5px;
    display: inline-block;
    transition: transform .5s ease;
  }

  &:hover {
    &:after {
      transform: translateX(.8rem);
    }
    span {
      border-bottom-color: $color-active
    }
  }
}
