.modal {
  display: none;

  &.is-open {
    display: block;
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.9);
  }

  &__container {
    position: relative;
    overflow-y: auto;
    box-sizing: border-box;
    max-width: 750px;
    max-height: 100vh;
    padding: 50px 100px;
    background-color: #fff;
  }

  &__content {
    margin-top: 30px;

    .popup-form,
    .form-thank {
      margin: 0 auto;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 30px;
    color: $text-color;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
  }

  &__close {
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;
    position: absolute;
    top: 15px;
    right: 15px;

    &:before {
      content: '\2715';
      font-size: 24px;
    }
  }
}
