.header {
  position: fixed;
  z-index: 5;
  width: 100%;
  .wrapper {

  display: flex;
  justify-content: space-between;
  height: 12rem;
  align-items: center;
  }
  &.fixed {
    background: #000;
  }


  &__logo {
    z-index: 6;
    display: inline-flex;
    margin: 0 5.4rem 0 0;
    flex-shrink: 0;

    @media (max-width: 1440px) {
      align-items: center;
      width: 200px;
    }

    img {
      max-width: 100%;
    }
  }

  &__btn {
    margin-right: -0.5rem;
    position: relative;
    z-index: 6;
    display: none;
    width: 45px;
    height: 40px;
    cursor: pointer;
    border: none;
    outline: none;

    @media (max-width: 1200px) {
      display: block;
    }

    &.active {
      .top {
        transform: translateY(16px) translateX(-50%) rotate(45deg);
        background: #FFCB05;
      }
      .middle {
        opacity: 0;
        background: #FFCB05;
      }

      .bottom {
        transform: translateY(-6px) translateX(-50%) rotate(-45deg);
        background: #FFCB05;
      }
    }

    span {
      position: absolute;
      top: 10px;
      left: 50%;
      width: 3.2rem;
      height: 1px;
      cursor: pointer;
      transition: all .35s ease;
      transform: translateX(-50%);
      border: none;
      background: $main-color;

      &:nth-of-type(2) {
        top: 22px;
      }

      &:nth-of-type(3) {
        top: 34px;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    flex-grow: 10;
    z-index: 10;
    .header-info {
      display: none;
    }
    @media (max-width: 1200px) {
        background: $text-color;
        padding-bottom: 3.8rem;
      left: 0;
      position: absolute;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100vh ;
      top: 0;
      padding-top: 0;
      transition: transform .3s linear;
      transform: translateX(100%);
      overflow: auto;
      .header-info {
        display: block;
      }
    }

    &.active {
      transform: translateX(0);
    }

    ul {
      display: flex;

      @media (max-width: 1200px) {
        width: 100%;
        align-items: center;
        flex: 1;
        flex-direction: column;
        padding-top: 9.5rem;
      }

      li {
        margin-right: 3.6rem;
        &:last-child {
          margin-right: 0;
        }
        @media (max-width: 1650px) {
          margin-right: 15px;
        }

        @media (max-width: 1200px) {
          margin-right: 0;
          margin-bottom: 30px;
        }

        @media (max-width: 360px) {
          margin-bottom: 15px;
        }
      }

      a {
        display: inline-block;
        height: 100%;
        font-size: 1.4rem;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        &:hover,
        &.active {
          color: $main-color;

        }
      }
    }
  }
  &-info {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  &__phone {
    display: flex;
    align-items: center;
    margin-left: 3.6rem;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: .1em;
    color: $main-color;
    i {
          font-size: 21px;
    margin-right: 9px;
    }
    &:hover {
      span {
        border-bottom-color: $main-color;
       }
    }


    span {
      border-bottom: 1px solid transparent;
      white-space: nowrap;
      transition: border-bottom-color .5s ease;
    }
  }


}
.lang {
    font-size: 1.4rem;
    margin-left: 4rem;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
}
.lang__item {
  border-right: 1px solid #888;padding-right: .7rem;
    margin-left: .5rem;
    a {
     
      color: #888;
    }
    &:last-child {
      border-right-width: 0;
      padding-right: 0
    }
}
.lang__item a.current {
  font-weight: 700;
  pointer-events: none;
}

.lang__item a:hover, .lang__item a.current {
     color: #fff;
}

  .header-dark {
    .header {
      
    background: #000;
    }
  }


@media (max-width: 1200px) {
  .header-info {
    z-index: 10;
    position: relative;
  }
  .header__logo,.header__btn {
    z-index: 11;
  }
    .header__nav {
      .header__phone {
      margin: 20px 0 0;
    }
    .lang {
      margin-left: 0;
      justify-content: center;
    }
    }
    .header__nav ul li {
    margin-bottom: 34px;
}
}
@media (max-width: 1023px) {
.header .wrapper {
    height: 8rem;
    }
  }
@media (max-width: 767px) {
  .header  {
    background: #000;
    }
  
    .header-info {
      display: none;
    }
.header .wrapper {
    height: 5.6rem;
    }
    .header__logo {
    width: 9.9rem;
}
.header__nav ul a {
    font-size: 2rem;
}
.lang {
    margin-left: 0;
    display: flex;
    justify-content: center;
    }
    .lang__item {
    border: 1px solid #888;
    text-align: center;
    margin: 0 .5rem;
    padding: 0;
    width: 4.8rem;
    a {
    padding: 1rem .5rem;
    display: inline-block;
    width: 100%;
    }
}
    .lang__item:last-child {
    border-right-width: 1px;
    padding-right: 0;
}
.lang__item a:hover, .lang__item a.current {
    color:#000;
    background:  #fff;
}
  }
@media (min-width: 1600px) {
  .header-info {
    margin-right: calc(807px - 50vw)
  }
}